import React from 'react';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        category
        categoryj
        date(formatString: "YYYY/MM/DD", locale: "ja-JP")
        slug
        image {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
      }
    }
  }
`;

type BaseTemplateProps = {
  className?: string;
  data: any;
};

const BaseTemplate: React.FC<BaseTemplateProps> = ({ className, data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const image = getImage(frontmatter?.image?.childImageSharp?.gatsbyImageData);
  return (
    <div className={className}>
      <h1 className="article-title">{frontmatter?.title ?? '(無題)'}</h1>
      <GatsbyImage image={image!} alt={''} />
      <div className="article-body" dangerouslySetInnerHTML={{ __html: html ?? '' }} />
    </div>
  );
};

const Template = styled(BaseTemplate)`
  ${() => {
    return css`
      & {
        .spacer30 {
          margin-bottom: 30px;
        }
      }
    `;
  }}
`;
export default Template;
